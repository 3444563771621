import { NotificationLevel } from "shared/NotificationSettingsModal/notificationTypes";

export enum TenancyType {
  Elevate = "elevate",
  Catalyst = "catalyst",
  Lept = "lept",
  Testing = "testing",
}

export enum AnalyticsMode {
  Off,
  AcademicOnly,
  StudentAndAcademic,
}

export enum CatalystPackage {
  CatalystCore = "catalystCore",
  BusinessAndManagement = "businessAndManagement",
  StudySkills = "studySkills",
  Professional = "professional",
  Professional2 = "professional2",
  ResearchMethods = "researchMethods",
}

export const CatalystPackageName = new Map<CatalystPackage, string>([
  [CatalystPackage.CatalystCore, "Catalyst Core"],
  [CatalystPackage.BusinessAndManagement, "Business and Management"],
  [CatalystPackage.StudySkills, "Study Skills"],
  [CatalystPackage.Professional, "Professional I"],
  [CatalystPackage.Professional2, "Professional II"],
  [CatalystPackage.ResearchMethods, "Research Methods"],
]);

export enum HierarchyTypeaheadSource {
  Off = "off",
  Tarl = "tarl",
}

export enum SendAnalyticEmails {
  On = "on",
  Off = "off",
  Debug = "debug",
}

export enum FeatureFlag {
  AllowContentItemTool = "allowContentItemTool",
  AllowCopyrightClearance = "allowCopyrightClearance",
  AllowEpubUpload = "allowEpubUpload",
  DisableZendesk = "disableZendesk",
  EnableGoogleAnalytics = "enableGoogleAnalytics",
  EnableEpubClassComments = "enableEPubClassComments",
}

export enum LtiModuleCodeFallback {
  ContextLabel = "context.label",
  ContextTitle = "context.title",
}

export type TenancyFeatureFlags = Partial<Record<FeatureFlag, boolean>>;

export type CatalystPackages = Partial<Record<CatalystPackage, boolean>>;

export type TenancyTypes = Partial<Record<TenancyType, boolean>>;

export type NewInstitution = {
  analyticsMode: AnalyticsMode;
  createdBy: string;
  hierarchyTypeaheadSource: HierarchyTypeaheadSource;
  name: string;
  sendAnalyticEmails: SendAnalyticEmails;
  shortCode: string;
  a2rAccountId?: string;
  allowedEmailDomains?: string;
  analyticEmailsBccAddress?: string;
  catalystPackages?: CatalystPackage[];
  defaultNotificationLevel?: NotificationLevel;
  features?: TenancyFeatureFlags;
  enableSaveEmbedDropdown?: boolean;
  rolesForLtiCiTool?: string;
  rolesForLtiDeepLinking?: string;
  ltiModuleCodeFallbackPreference?: LtiModuleCodeFallback;
  tenancyTypes: TenancyTypes;
  textbooksFileName?: string;
  trustLTIProfileDetails?: boolean;
  inactive?: boolean;
  autoProfileEnabled?: boolean;
};

export type Institution = NewInstitution & {
  _id: string;
};

export type InstitutionFormData = Omit<Institution, "catalystPackages"> & {
  catalystPackages?: CatalystPackages;
};

export type NewInstitutionFormData = Omit<
  NewInstitution,
  "catalystPackages"
> & {
  catalystPackages?: CatalystPackages;
};
